import * as React from 'react';
import objectHash from 'object-hash';
import { FilterWrapper } from './FilterWrapper';
import { IFilterComponentProps } from './FilterComponentProps';
import { ISelectItem, MultiselectType, Select } from '../../form';
import { MultiSelectFilter } from './MultiSelectFilter';
import { getRandomHash } from '../../helpers/RandomHash';
import { QualifyingStatusEnum, WorkflowTypeEnum } from '@usga/champadmin-api';

function mapToSelectItems<T>(
  items: Array<T>,
  titleMapper: (value: T) => string
): Array<ISelectItem<T>> {
  return items.map((item) => {
    return {
      id: objectHash(item),
      value: item,
      title: titleMapper(item),
    };
  });
}

export function makeFilter<T extends unknown>(
  items: T[],
  mapTitle: (value: T) => string = (value: T) => String(value)
) {
  return function EnumFilter({
    onValueChange,
    value,
    onRemoveClick,
    title,
    disabled,
    workflowType,
  }: IFilterComponentProps<T>) {
    const filteredItems =
      workflowType && [WorkflowTypeEnum.USOPEN, WorkflowTypeEnum.USAMATEUR].includes(workflowType)
        ? items.filter(
            (it) =>
              ![
                QualifyingStatusEnum.SINGLESTAGEFINALIST,
                QualifyingStatusEnum.SINGLESTAGEFINALIST1STALTERNATE,
                QualifyingStatusEnum.SINGLESTAGEFINALIST2NDALTERNATE,
                QualifyingStatusEnum.SINGLESTAGEQUALIFYING,
              ].includes((it as unknown) as QualifyingStatusEnum)
          )
        : items.filter(
            (it) =>
              ![
                QualifyingStatusEnum.FINALSTAGEFINALIST,
                QualifyingStatusEnum.FINALSTAGEFINALIST1STALTERNATE,
                QualifyingStatusEnum.FINALSTAGEFINALIST2NDALTERNATE,
                QualifyingStatusEnum.FINALSTAGEQUALIFYING,
                QualifyingStatusEnum.FIRSTSTAGEQUALIFYING,
                QualifyingStatusEnum.FIRSTSTAGEQUALIFYING1STALTERNATE,
                QualifyingStatusEnum.FIRSTSTAGEQUALIFYING2NDALTERNATE,
              ].includes((it as unknown) as QualifyingStatusEnum)
          );
    const selectItems = mapToSelectItems(filteredItems, mapTitle);
    const selectIdRef = React.useRef(`enum-filter-${getRandomHash()}`);
    return (
      <FilterWrapper onRemoveClick={onRemoveClick}>
        <Select
          disabled={disabled}
          isDisabled={disabled}
          customAutocomplete
          width={onRemoveClick ? '200px' : '224px'}
          id={selectIdRef.current}
          items={selectItems}
          val={value}
          handleChange={onValueChange}
          placeholder={title}
        />
      </FilterWrapper>
    );
  };
}

interface IMultiSelectFilterProps<T extends MultiselectType>
  extends IFilterComponentProps<Array<T>> {
  isLoading?: boolean;
  isDisabled?: boolean;
}

export function makeMultiSelectFilter<T extends MultiselectType>(
  items: Array<T>,
  mapTitle: (val: T) => string = String,
  selectAllTitle?: string,
  hideSelectAll?: boolean,
  width?: string
) {
  const selectItems = mapToSelectItems(items, mapTitle);
  return function MultiSelectAutoFilter(props: IMultiSelectFilterProps<T>) {
    return (
      <MultiSelectFilter
        width={width}
        items={selectItems}
        selectAllTitle={selectAllTitle}
        hideSelectAll={hideSelectAll}
        isDisabled={props.disabled}
        disabled={props.disabled}
        {...props}
      />
    );
  };
}
